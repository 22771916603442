
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* React loading lazy */
.loader-body-react {
  display: flex;
  justify-content: center;
  margin-top: 25%;
  /* align-items: center;
  width: 100%; */
  /* margin-top: 30%;
  margin-right: 10px; */
  /*   margin-right: 15%;
  margin-top: 20%; */
text-align: center;
  height: 17vh;

  /****** center box
  width: 300px;
  height: 300px;
  border: solid 1px #aaa; */
}
.loader-text-react {
  color: rgb(5, 144, 237);
  text-decoration: underline;
  text-align: center;
}
.loader-circle-react {
  /*   margin-left: -128px; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 128px;
  width: 128px;
  position: relative;
}
.loader-circle-react::after,
.loader-circle-react::before {
  /*   margin-left: 64px; */
  content: "";
  width: 128px;
  height: 128px;
  position: absolute;
  border: solid 8px transparent;
  border-radius: 50%;
  animation: loader-circle-spin 1.4s ease infinite;
}
.loader-circle-react::before {
  border-top-color: #76f935;
  border-bottom-color: #76f935;
}
.loader-circle-react::after {
  border-left-color: #76f935;
  border-right-color: #76f935;
  animation-delay: 0.7s;
}
@keyframes loader-circle-react-spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
.pera-wallet-connect-modal {
  z-index: 2000 !important;
}
.defly-wallet-connect-modal {
  z-index: 2000 !important;
}

.pera-wallet-modal {
  z-index: 2000 !important;
}
.pera-wallet-sign-txn-toast {
  z-index: 2000 !important;
}

.defly-wallet-modal {
  z-index: 2000 !important;
}
.defly-wallet-sign-txn-toast {
  z-index: 2000 !important;
}
.daffi-wallet-connect-modal {
  z-index: 2000 !important;
}
.daffi-wallet-modal {
  z-index: 2000 !important;
}
.daffi-wallet-sign-txn-toast {
  z-index: 2000 !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 47% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 47% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 39% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 20% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1024px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 29% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .walletconnect-qrcode__base {
    position: relative !important;
    width: 640px !important;
    max-width: 640px !important;
    left: 13% !important;
  }
}


.loop-wrapper {
  margin: 0 auto;
  position: relative;
  display: block;
  /* width: 600px; */
  height: 120px;
  overflow: hidden;
  border-bottom: 3px solid #76F935;
  color: #fff;
}
.plaus-page-loader {

  background: url(../public/images/loader.svg) no-repeat;

 
}
.loader-animation {
  margin: 0px;
  transform: scale(-1, 1);
  text-align: center;
  opacity: 0.4;
  position: relative;
  display: block;
  background: url(../public/images/loader.svg) no-repeat;

  background-size: cover;
  
  /* width: 100%; */
  height: 500px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  /* border-bottom: 3px solid #76F935; */
  color: #fff;
}

#react-qrcode-logo{
  border-radius: 25px;
}
.rc-anchor{
  border-radius: 12px !important;
}

