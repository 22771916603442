body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(198deg, rgba(174, 1, 247, 0.66) 0%, rgba(103, 3, 243, 0.706) 25%, #f4ecf9 100%);
  background-size: cover;

  font-family:'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Muli', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

